import { motion } from "framer-motion";
import { useRef } from "react";

import bitty from "./assets/bitty.png";
import bitty2 from "./assets/bitty2.png";
import cloud from "./assets/cloud.png";
import ittyBitty from "./assets/itty/ittyBitty.jpg";

import './App.css';

function App() {
  const about = useRef();

  return (
    <div className="App">
      <div
        style={{ display: "flex", padding: ".25rem", justifyContent: "space-between", alignItems: "center" }}>
        <div
          style={{ display: "flex", gap: ".5rem", justifyContent: "space-between", alignItems: "center" }}
        >
          <img
            alt=""
            height={30}
            style={{ borderRadius: "50%" }}
            src={"https://i.imgur.com/GZ4YPtI.jpeg"}>
          </img>

          <div
            style={{ fontSize: "1rem", marginTop: 0 }}
            className="CasperHeader">
            BOXEDCAT on solana
          </div>
        </div>

        <div
          style={{ display: "flex", alignItems: "center" }}>
          <button
            onClick={() => window.open("https://t.me/+9QHJzkZl4RQxYjBh")}>

            <span class="text">Telegram</span>
            <span class="shimmer"></span>

          </button>
          <button
            onClick={() => window.open("https://x.com/catinaboxsol")}>

            <span class="text">Twitter</span>
            <span class="shimmer"></span>

          </button>
        </div>
      </div>

      <div style={{ alignContent: "center", justifyContent: "center" }}>
        <header
          className="CasperHeader">
          $BOXEDCAT
        </header>

        <div style={{ zIndex: 10, display: "grid", justifyContent: "center", alignItems: "center" }}>
          <button
            style={{ width: "250px", marginLeft: "auto", marginRight: "auto", position: "relative", top: 500, zIndex: 3 }}
            onClick={() => about.current.scrollIntoView({ behavior: "smooth" })}>

            <span class="text">Itty Bitty Kitty</span>
            <span class="shimmer"></span>

          </button>
          <motion.img
            initial={{ y: -250, zIndex: 2 }}
            animate={{ y: 0, zIndex: 2 }}
            transition={{ type: "spring", stiffness: 25 }}
            height={"350px"}
            src={bitty2}></motion.img>
        </div>

        <div>
          <motion.img
            style={{ display: "grid", justifyContent: "center", marginLeft: "auto", marginRight: "auto", maxWidth: "50dvh" }}
            initial={{ y: 100 }}
            animate={{ y: -200 }}
            transition={{ type: "spring", stiffness: 25 }}
            height={350}
            src={cloud}></motion.img>
        </div>

        <div style={{ display: "flex", padding: "1rem", justifyContent: "center" }}>
          <div>
            <div
              className="CasperDescription">
              Unleash the purr-sibilities!

              Catbox - The meme token that's full of surprises.
            </div>

            <motion.img

              initial={{ x: 0 }}
              animate={{ x: 35, y: 0 }}
              transition={{ type: "spring", stiffness: 100 }}

              style={{ display: "grid", justifyContent: "center" }}
              height={100} src={bitty}>

            </motion.img>
          </div>

          <div>
            <motion.img
              initial={{ x: 0 }}
              animate={{ x: 25, y: 0 }}
              transition={{ type: "spring", stiffness: 100 }}

              style={{ display: "grid", justifyContent: "center" }}
              height={100} src={bitty}>

            </motion.img>

            <div
              className="CasperDescription">
              $BOXEDCAT will be beta tested in the first ever play to earn stream game.
            </div>
          </div>
        </div>
      </div>

      <header
        ref={about}
        className="CasperHeader">
        Who Is Itty Bitty?
      </header>

      <div style={{ display: "grid" }}>
        <div
          style={{ marginLeft: "auto", marginRight: "auto", display: "grid", justifyContent: "center" }}
          className="CasperDescription">
          <div style={{ display: "flex" }}>
            <div style={{ display: "grid", justifyContent: "center", alignContent: "center", width: 250 }}>
              <img
                alt=""
                height={333}
                src={ittyBitty}
                style={{ borderRadius: "2rem", borderColor: "rgb(255, 131, 152)", borderWidth: "10px", borderStyle: "solid", display: "grid", justifyContent: "center", alignItems: "center", marginLeft: "auto", marginRight: "auto" }}
              >

              </img>

              <div
                style={{ padding: 0, fontSize: "1.25rem", textAlign: "center" }}
                className="CasperDescription">
                Itty bitty is a well loved house cat that inspired a charity train through streamers to help charities around the world!
              </div>
            </div>

            <div style={{ display: "grid", alignContent: "center", gap: "2rem" }}>
              <img
                height={100}
                alt=""
                src={"https://i.imgur.com/xN2DUCA.png"}
                style={{ borderRadius: "2rem", borderColor: "rgb(255, 131, 152)", borderWidth: "10px", borderStyle: "solid", display: "grid", justifyContent: "center", alignItems: "center", marginLeft: "auto", marginRight: "auto" }}
              >

              </img>

              <img
                height={100}
                alt=""
                src={"https://i.imgur.com/E0ZM4xH.png"}
                style={{ borderRadius: "2rem", borderColor: "rgb(255, 131, 152)", borderWidth: "10px", borderStyle: "solid", display: "grid", justifyContent: "center", alignItems: "center", marginLeft: "auto", marginRight: "auto" }}
              >

              </img>

              <img
                height={100}
                alt=""
                src={"https://i.imgur.com/S6THZSA.png"}
                style={{ borderRadius: "2rem", borderColor: "rgb(255, 131, 152)", borderWidth: "10px", borderStyle: "solid", display: "grid", justifyContent: "center", alignItems: "center", marginLeft: "auto", marginRight: "auto" }}
              >

              </img>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: "grid", textAlign: "center", gap: "1rem", padding: "1rem" }}>
        <div
          style={{ marginBottom: "3rem" }}
          className="CasperDescription">
          <div
            className="casperDescription"
            style={{ textTransform: "uppercase", marginBottom: "3rem", color: "white", fontSize: "1.25rem" }}>
            Disclaimer
          </div>

          <div className="casperDescription" style={{ fontSize: "1.25rem" }}>
            $BOXEDCAT is created to help spread the movement of the charity train for streamers.
          </div>
        </div>
      </div>
    </div >
  );
}

export default App;
